import React from "react";

function Footer() {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="copy-rgt">
              © Copyright <span id="spFY"></span> All rights reserved by TIGI
              Industries India Pvt. Ltd.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
