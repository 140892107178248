import React from "react";
import { Link } from "react-router-dom";
import linkedin from "../assets/linked.png";

function Contact() {
  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <h3 className="cnt-head">Contact Us</h3>
          <p className="cnt-para">
            We’d love to hear from you! For inquiries, please reach out to us
            using the information below.
          </p>
          <div className="col-lg-6 col-md-6 col-sm-12 cnt">
            <h3 className="cnt-tigi">TIGI Industries India Pvt. Ltd.</h3>
            <p className="cnt-info">
              Plot No. 26, B Nanji Industrial Estate, Kharadpada, Silvasa
              396230.
            </p>
            <h5 className="cnt-tigi">Phone</h5>
            <p className="cnt-info">+91 90042 90293 | +91 77381 56675</p>
            <h5 className="cnt-tigi">Email</h5>
            <p className="cnt-info">
              sales@tigiindustries.in | tjt@tigiindustries.in
            </p>
            <Link
              to="https://wa.me/+919004290293"
              className="btn btn-light Whatsapp"
            >
              Message us on Whatsapp
            </Link>
            <br />
            <Link
              to="https://www.linkedin.com/company/tigi-industries-india-pvt-ltd/"
              target="_blank"
            >
              <img className="social" src={linkedin} alt="LinkedIn" />
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59882.36405063275!2d72.94332645738773!3d20.273438435306247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be73312b4f0e073%3A0xe9e01feec57e9815!2sTIGI%20Industries%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1720850937409!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
