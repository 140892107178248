import React from "react";
import main from "../assets/about-main.jpg";
import vision from "../assets/vision.jpg";
import mission from "../assets/mission.jpg";

function About() {
  return (
    <div>
      <img src={main} alt="about" className="img-fluid" />
      <section id="abt-main">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="zoom-in">
              <h2 className="about-header">Who we Are</h2>
              <p className="about-para">
                Welcome to TIGI Industries India Pvt. Ltd., where innovation
                meets excellence in packaging solutions. Founded by a team of
                professionals with over 30 years of experience, we specialize in
                flexible packaging, blow and injection molding, and a variety of
                bulk industrial packaging solutions. Our commitment to quality
                and sustainability drives us to continually enhance our
                offerings, ensuring that we meet the diverse needs of our
                customers.
              </p>
              <p className="about-para">
                At TIGI Industries, we understand that packaging is not just
                about containment; it's about creating value and enhancing the
                user experience. Our customer-centric approach allows us to
                tailor solutions that not only meet but exceed expectations,
                making us a trusted partner for businesses across various
                sectors.
              </p>
              <p className="about-para">
                We are dedicated to maximizing operational efficiency while
                fostering a culture of innovation and collaboration. Our goal is
                to be at the forefront of the packaging industry, leveraging
                cutting-edge technology and sustainable practices to deliver
                superior products. Join us on our journey to redefine packaging
                and create a lasting impact on our clients and the environment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="vision">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12" data-aos="slide-up">
              <img src={vision} alt="vision" className="img-fluid vision-img" />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 vision-info"
              data-aos="slide-down"
            >
              <h3 className="vision">Vision</h3>
              <p className="vision-para">
                At TIGI Industries India Pvt. Ltd., our vision is to be the
                foremost leader in the packaging industry, renowned for our
                innovative and sustainable solutions that not only meet the
                diverse needs of our customers but also enhance their overall
                experience. With a commitment to excellence and a focus on
                maximizing operational efficiency, we aim to revolutionize
                packaging through cutting-edge technology and environmentally
                responsible practices. By fostering a culture of collaboration
                and continuous improvement, we aspire to create lasting value
                for our clients and a positive impact on the world around us.
                Together, we will redefine the future of packaging, setting new
                standards in quality and sustainability.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="mission">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 mission-info"
              data-aos="slide-up"
            >
              <h3 className="mission">Mission</h3>
              <p className="mission-para">
                At TIGI Industries India Pvt. Ltd., we are dedicated to
                delivering innovative and sustainable packaging solutions. Our
                mission is to exceed customer expectations through tailored
                products and services in flexible packaging, blow and injection
                molding, and bulk industrial packaging. We prioritize
                operational efficiency and foster a culture of collaboration and
                continuous improvement. By leveraging advanced technologies and
                eco-friendly practices, we enhance user experiences while
                minimizing our environmental impact. We aim to build long-term
                partnerships across diverse sectors, empowering our clients to
                thrive and contribute to a sustainable future. Together, we
                redefine excellence in packaging.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12" data-aos="slide-down">
              <img
                src={mission}
                alt="mission"
                className="img-fluid mission-img"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
