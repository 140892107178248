import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import cardimage1 from "../assets/experience.jpg";
import cardimage2 from "../assets/packing.jpg";
import cardimage3 from "../assets/customer.jpg";
import cardimage4 from "../assets/quality.jpg";
import aboutimg from "../assets/agreement.jpg";
import linkedin from "../assets/linked.png";

function Home() {
  return (
    <div>
      {/* home-main */}
      <section id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="home-heading">
                TIGI <br /> INDUSTRIES
              </h2>
              <p className="home-subline">
                Empowering Brands with Exceptional Packaging Solutions, Tailored
                to Perfection.
              </p>
              <ScrollLink
                to="why"
                smooth={true}
                duration={500}
                className="btn btn-red knw-more"
              >
                Know More
              </ScrollLink>
            </div>
          </div>
        </div>
      </section>

      {/* why-section */}
      <section id="why">
        <div className="container">
          <div className="row">
            <h2 className="why-heading">Why Choose Us</h2>
            <p className="why-subline">
              At TIGI Industries India Pvt. Ltd., we stand out in the packaging
              industry for several compelling reasons.
            </p>
            <Card
              image={cardimage1}
              title="Expertise and Experience"
              text="With over 30 years in the industry, we deliver exceptional quality and innovative solutions for every project."
            />
            <Card
              image={cardimage2}
              title="Diverse Packaging Solutions"
              text="We specialize in flexible packaging, blow and injection molding, and bulk industrial packaging to meet diverse customer needs."
            />
            <Card
              image={cardimage3}
              title="Customer-Centric Approach"
              text="We prioritize our clients' unique needs, tailoring our offerings to exceed expectations and enhance the overall user experience."
            />
            <Card
              image={cardimage4}
              title="Commitment to Quality and Sustainability"
              text="We uphold quality standards and embrace sustainability, ensuring our products are effective and environmentally responsible."
            />
            <p className="bottom-para">
              Join us in redefining packaging solutions that create lasting
              value for your business and a positive impact on the environment.
            </p>
          </div>
        </div>
      </section>

      {/* about-section */}
      <section id="abt">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src={aboutimg}
                alt="main about"
                className="img-fluid abt-main-img"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 abt-info">
              <h3 className="abt-main-head">Who We Are</h3>
              <p className="abt-main-para">
                Welcome to TIGI Industries India Pvt. Ltd., where innovation
                meets excellence in packaging solutions. Founded by a team of
                professionals with over 30 years of experience, we specialize in
                flexible packaging, blow and injection molding, and a variety of
                bulk industrial packaging solutions. Our commitment to quality
                and sustainability drives us to continually enhance our
                offerings, ensuring that we meet the diverse needs of our
                customers.
              </p>
              <Link to="/about" className="abt-main-btn btn btn-light">
                Know More
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* contact-section */}
      <section id="contact">
        <div className="container">
          <div className="row">
            <h3 className="cnt-head">Contact Us</h3>
            <p className="cnt-para">
              We’d love to hear from you! For inquiries, please reach out to us
              using the information below.
            </p>
            <div className="col-lg-6 col-md-6 col-sm-12 cnt">
              <h3 className="cnt-tigi">TIGI Industries India Pvt. Ltd.</h3>
              <p className="cnt-info">
                Plot No. 26, B Nanji Industrial Estate, Kharadpada, Silvasa
                396230.
              </p>
              <h5 className="cnt-tigi">Phone</h5>
              <p className="cnt-info">+91 90042 90293 | +91 77381 56675</p>
              <h5 className="cnt-tigi">Email</h5>
              <p className="cnt-info">
                sales@tigiindustries.in | tjt@tigiindustries.in
              </p>
              <Link
                to="https://wa.me/+919004290293"
                className="btn btn-light Whatsapp"
              >
                Message us on Whatsapp
              </Link>
              <br />
              <Link
                to="https://www.linkedin.com/company/tigi-industries-india-pvt-ltd/"
                target="_blank"
              >
                <img className="social" src={linkedin} alt="LinkedIn" />
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59882.36405063275!2d72.94332645738773!3d20.273438435306247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be73312b4f0e073%3A0xe9e01feec57e9815!2sTIGI%20Industries%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1720850937409!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const Card = ({ image, title, text }) => (
  <div className="col-lg-3 col-md-6 col-sm-12" data-aos="fade-up">
    <div className="card" style={{ width: "18rem" }}>
      <img src={image} className="card-img-top" alt={title} />
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{text}</p>
      </div>
    </div>
  </div>
);

export default Home;
