import React, { useState } from "react";
import flexible from "../assets/products/flexible.webp";
import hdpe from "../assets/products/hdpeone.jpg";
import ldpe from "../assets/products/shrink.jpg";
import strech from "../assets/products/strech.jpg";
import ziplock from "../assets/products/pouches.jpg";
import fibc from "../assets/products/Flexible.jpg";
import woven from "../assets/products/wovenbag.jpg";
import garbage from "../assets/products/garbage-bag-5-jpg.webp";
import { Link } from "react-router-dom";

const products = [
  {
    id: 1,
    name: "Flexible Packaging",
    image: flexible, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we specialize in high-quality laminated
          rotogravure printed rolls and pouches, providing innovative packaging
          solutions for a wide range of industries, including food,
          pharmaceuticals, and consumer goods. Our advanced rotogravure printing
          technology ensures vibrant, durable, and precise designs that enhance
          your product's visibility.
        </p>
        <h6>Our packaging options include:</h6>
        <ul>
          <li>Stand-up pouches</li>
          <li>Side gusseted pouches</li>
          <li>Zipper pouches</li>
          <li>Three-side seal pouches</li>
        </ul>
        <h6>Laminated Structures:</h6>
        <ul>
          <li>
            <strong>PET/PE:</strong> Strong and clear, suitable for general
            packaging.
          </li>
          <li>
            <strong>PET/Met PET/PE:</strong> Excellent barrier for moisture and
            oxygen, ideal for snacks and dry foods.
          </li>
          <li>
            <strong>BOPP/CPP:</strong> Flexible and transparent, commonly used
            in food packaging.
          </li>
          <li>
            <strong>PET/AL/PE:</strong> High-barrier structure for products
            requiring longer shelf life, like coffee and tea.
          </li>
        </ul>
        <p>
          Whether you need packaging in roll form or custom-designed pouches,
          our solutions offer superior protection, extended shelf life, and
          exceptional design quality.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    name: "HDPE Carboys",
    image: hdpe, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we offer a wide range of high-quality HDPE drums,
          available in capacities from 20 liters to 65 liters, designed for the
          safe and reliable storage of liquids and chemicals. Manufactured from
          durable high-density polyethylene (HDPE), our drums provide excellent
          resistance to impact, chemicals, and UV exposure, ensuring your
          products are well-protected during transportation and storage. These
          versatile drums are ideal for industries such as chemicals,
          pharmaceuticals, food, and agriculture. With features like tight
          sealing, stackability, and easy handling, our HDPE drums meet the
          highest industry standards for performance and safety.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    name: "LDPE Shrink Film",
    image: ldpe, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we specialize in producing high-quality LDPE
          shrink films and stretch films designed to provide secure and
          efficient packaging solutions. Our LDPE (Low-Density Polyethylene)
          films offer excellent clarity, flexibility, and durability, ensuring
          that your products are protected during storage and transportation.
        </p>
        <p>
          <strong>LDPE Shrink Films:</strong>Ideal for bundling products and
          providing a tight, protective layer, these films shrink uniformly when
          heat is applied, making them perfect for packaging items such as
          beverages, food, and consumer goods.
        </p>
        <p>
          Our films are customizable to meet various industrial needs, ensuring
          your products stay safe while enhancing efficiency in packaging
          processes.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    name: "LDPE Stretch Films",
    image: strech, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we specialize in producing high-quality stretch
          films designed to provide secure and efficient packaging solutions.
          Our LDPE (Low-Density Polyethylene) films offer excellent clarity,
          flexibility, and durability, ensuring that your products are protected
          during storage and transportation.
        </p>

        <p>
          <strong>LDPE Stretch Films:</strong> Designed for pallet wrapping and
          load stabilization, these films stretch easily to secure products
          tightly without tearing, offering superior load protection and
          reducing the risk of damage during transit.
        </p>

        <p>
          Our films are customizable to meet various industrial needs, ensuring
          your products stay safe while enhancing efficiency in packaging
          processes.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    name: "Ziplock Pouch",
    image: ziplock, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we offer premium <strong>PE liners</strong> and{" "}
          <strong>Ziplock</strong> Pouches that provide reliable packaging
          solutions for a wide range of industries. Made from high-quality
          polyethylene (PE), our liners and pouches are designed for durability,
          product protection, and ease of use.
        </p>

        <p>
          <strong>PE Liners</strong>: Ideal for use as an inner layer in bags or
          containers, these liners protect products from contamination,
          moisture, and external elements. They are commonly used in industries
          such as food, chemicals, and pharmaceuticals.
        </p>

        <p>
          <strong>Ziplock Pouches</strong>: Our resealable ziplock pouches are
          perfect for keeping contents fresh and secure. They are widely used
          for packaging food items, electronics, pharmaceuticals, and more,
          offering convenience and product visibility.
        </p>

        <p>
          Both our PE liners and ziplock pouches are customizable to meet your
          specific packaging needs, ensuring safe storage and hassle-free
          handling.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    name: "FIBC",
    image: fibc, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we supply high-quality Flexible Intermediate Bulk
          Containers (FIBCs), commonly known as jumbo bags, designed for the
          efficient storage and transportation of bulk materials. Our jumbo bags
          are made from durable woven polypropylene, offering superior strength
          and reliability for handling heavy loads across industries such as
          agriculture, chemicals, construction, and food processing.{" "}
          <Link to="https://instapack.info/">Click Here for FIBC</Link>
        </p>

        <p>
          We provide a wide variety of FIBC types to suit your specific needs,
          including:
        </p>

        <ul>
          <li>U-Panel Bags.</li>
          <li>Circular/Tubular Bags.</li>
          <li>
            Baffle Bags (Q-bags) for better shape retention and space
            optimization.
          </li>
          <li>Ventilated Bags for products like potatoes and onions.</li>
          <li>
            Single-Loop and Two-Loop Bags for easy handling with fewer lifting
            points.
          </li>
        </ul>

        <p>
          Our jumbo bags come with various options such as coated or uncoated
          fabrics, UV protection, and liners for moisture-sensitive products.
          They are customizable to meet your exact requirements for size, load
          capacity, and design, ensuring safe and efficient bulk handling.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    name: "Woven Bags",
    image: woven, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we manufacture high-quality woven bags, offering
          durable and cost-effective packaging solutions for a wide range of
          industries, including agriculture, construction, chemicals, and food
          products. Made from strong woven polypropylene (PP) fabric, our woven
          bags are designed to provide excellent strength, tear resistance, and
          protection for heavy or bulk items.
        </p>

        <p>
          Our woven bags are available in various sizes, with options for
          customization, including:
        </p>

        <ul>
          <li>Laminated or unlaminated finishes</li>
          <li>Printed designs for branding</li>
          <li>UV protection for outdoor storage</li>
        </ul>

        <p>
          Ideal for packaging products like grains, fertilizers, cement, and
          more, our woven bags offer superior durability, making them a trusted
          choice for secure transportation and storage.
        </p>
      </div>
    ),
  },
  {
    id: 8,
    name: "Garbage Bags",
    image: garbage, // Updated
    description: (
      <div>
        <p>
          At Tigi Industries, we produce high-quality{" "}
          <strong>garbage bags</strong> designed for durability, strength, and
          convenience. Made from premium-grade polyethylene, our garbage bags
          are tear-resistant, leak-proof, and ideal for managing waste in
          households, offices, industrial sites, and commercial spaces.
        </p>

        <p>
          We offer a variety of garbage bags to suit your specific needs,
          including:
        </p>

        <ul>
          <li>Different sizes for light to heavy-duty waste</li>
          <li>
            Biodegradable and eco-friendly options for sustainable waste
            management
          </li>
        </ul>

        <p>
          Our garbage bags provide a reliable solution for clean, efficient, and
          hygienic waste disposal, ensuring a safer and cleaner environment.
        </p>
      </div>
    ),
  },
];

const ProductCards = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
  };

  return (
    <div className="container">
      <div className="row">
        {/* Render Product Cards */}
        {products.map((product) => (
          <div
            className="col-lg-3 col-md-3 col-sm-12"
            key={product.id}
            data-aos="flip-up"
          >
            <div className="card" style={{ width: "18rem" }}>
              <img
                src={product.image}
                className="card-img-top"
                alt={product.name}
              />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <button
                  type="button"
                  className="btn btn-primary prod-btn"
                  onClick={() =>
                    openModal(
                      <div>
                        <img
                          src={product.image}
                          alt={product.name}
                          className="img-fluid"
                        />
                        <h5>{product.name}</h5>
                        <p>{product.description}</p>
                      </div>
                    )
                  }
                >
                  Know More
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Modal */}
        {showModal && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Product Details
                  </h5>
                </div>
                <div className="modal-body">{modalContent}</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary btn-modal"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCards;
