import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import image from "../assets/gallery/gallery.jpeg";
import image1 from "../assets/gallery/gallery1.jpg";
import image2 from "../assets/gallery/gallery2.jpeg";
import image3 from "../assets/gallery/gallery3.jpg";
import image4 from "../assets/gallery/gallery4.jpeg";
import image5 from "../assets/gallery/gallery5.jpg";
import image6 from "../assets/gallery/gallery6.jpeg";
import image7 from "../assets/gallery/gallery7.jpg";
import image8 from "../assets/gallery/gallery8.jpeg";
import image9 from "../assets/gallery/gallery9.jpg";

const images = [
  { src: image },
  { src: image1 },
  { src: image2 },
  { src: image3 },
  { src: image4 },
  { src: image5 },
  { src: image6 },
  { src: image7 },
  { src: image8 },
  { src: image9 },
];

function Gallery() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    console.log("Opening Lightbox for index:", index); // Debugging
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <div>
      <section id="gallery" data-aos="zoom-in-up">
        <div className="container">
          <div className="gallery">
            <h3 className="gallery-heading">Visual Journey</h3>
            <p className="gallery-para">
              Discover Our Commitment to Excellence: Innovative Packaging
              Solutions That Inspire
            </p>

            <div className="img-container">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="image"
                  onClick={() => openLightbox(index)}
                >
                  <img
                    className="img"
                    src={image.src}
                    alt="gallery img"
                    onError={(e) =>
                      (e.target.src =
                        "https://via.placeholder.com/150?text=Image+Not+Found")
                    } // Fallback placeholder
                  />
                </div>
              ))}
            </div>

            {isOpen && (
              <Lightbox
                mainSrc={images[currentImageIndex]?.src || ""}
                nextSrc={
                  images[(currentImageIndex + 1) % images.length]?.src || ""
                }
                prevSrc={
                  images[
                    (currentImageIndex + images.length - 1) % images.length
                  ]?.src || ""
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setCurrentImageIndex(
                    (currentImageIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setCurrentImageIndex((currentImageIndex + 1) % images.length)
                }
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gallery;
